(function ($, ServiceBus, ServiceTopics) {
  if (!ServiceTopics.events
    || !ServiceTopics.events.BASIC_TOUT_LOADED) {
    return;
  }

  function anchoringPositionUpdate() {
    var targetId = window.location.hash.substring(1);
    // Scroll to the target element if it exists
    var $targetElement = targetId ? $('#' + targetId) : [];

    if ($targetElement.length > 0 && $targetElement.hasClass('elc-sd-content-anchor')) {
      setTimeout(function () {
        $('html, body').animate({ scrollTop: $targetElement?.offset()?.top });
      }, 500);
    }
  }

  ServiceBus.on(ServiceTopics.events.BASIC_TOUT_LOADED, function () {
    var isLiveChatCta = false;
    var $toutCtaButtons = $('.js-basic-tout__link .js-mantle-cta-button');

    $toutCtaButtons.each(function () {
      var $toutCta = $(this);

      if (typeof $toutCta[0].id !== 'undefined') {
        isLiveChatCta = true;
      }
    });

    if (isLiveChatCta && typeof lpTag !== 'undefined') {
      $toutCtaButtons.not(':visible').remove();
      // Refresh live chat button after tout loaded
      lpTag?.newPage?.(window.location.href);
    }

    anchoringPositionUpdate();
  });

  ServiceBus.on(ServiceTopics.events.PRODUCT_GRID_VIEWED, function () {
    anchoringPositionUpdate();
  });
})(
  jQuery,
  window.GlobalServiceBus || {},
  window.ServiceBusTopics || {}
);
